<template>
  <div>
    <RWCard>
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M13 18.945V20h1a2 2 0 012 2H8a2 2 0 012-2h1v-1.055a8.972 8.972 0 01-5.206-2.427l-1.055 1.156a1 1 0 01-1.478-1.348l1.788-1.959a1 1 0 011.515.044 7 7 0 109.557-10.07 1 1 0 111.18-1.615 9.001 9.001 0 01-4.3 16.22z" fill="#000" fill-rule="nonzero"/><circle fill="#000" opacity=".3" cx="12" cy="10" r="6"/></g></svg>
          Submit Ticket
        </h3>
      </template>
      <template v-slot:body>
        <b-form>
          <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              type="email"
              required
              placeholder="Enter email"
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary">Submit</b-button>
        </b-form>
      </template>
    </RWCard>

    <!-- contact -->
    <b-row>
      <b-col sm="6">
        <RWCard>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.386-8.787a1.462 1.462 0 01-1.688.274 1.462 1.462 0 00-1.688.274l-1.511 1.51a.5.5 0 00.195.829l1.354.451c1.3.433 2.733.095 3.702-.874l4-4c.89-.89 1.11-2.25.547-3.375l-.632-1.266a.5.5 0 00-.8-.13L13.5 8.5a1.462 1.462 0 00-.274 1.688 1.462 1.462 0 01-.274 1.688l-1.338 1.338z" fill="#000"/></g></svg>
              Telepon
            </h3>
          </template>
          <template v-slot:body>
            <b-list-group>
              <b-list-group-item href="tel:+62274882257"
                >0274-882257</b-list-group-item
              >
              <b-list-group-item href="tel:+622125556765"
                >021-25556765</b-list-group-item
              >
            </b-list-group>
          </template>
        </RWCard>
      </b-col>
      <b-col sm="6">
        <RWCard>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M6 2h12a1 1 0 011 1v10a1 1 0 01-1 1H6a1 1 0 01-1-1V3a1 1 0 011-1zm7.8 2c-.644 0-1.397.73-1.8 1.2-.403-.47-1.156-1.2-1.8-1.2-1.14 0-1.8.889-1.8 2.02C8.4 7.273 9.6 8.6 12 10c2.4-1.4 3.6-2.7 3.6-3.9 0-1.131-.66-2.1-1.8-2.1z" fill="#000" opacity=".3"/><path d="M3.793 6.573L12 12.5l8.207-5.927a.5.5 0 01.793.405V17a2 2 0 01-2 2H5a2 2 0 01-2-2V6.978a.5.5 0 01.793-.405z" fill="#000"/></g></svg>
              Email
            </h3>
          </template>
          <template v-slot:body>
            <b-list-group>
              <b-list-group-item href="mailto:billing@rumahweb.com"
                ><b>Billing :</b> billing@rumahweb.com</b-list-group-item
              >
              <b-list-group-item href="mailto:info@rumahweb.com"
                ><b>Email/Contact :</b> info@rumahweb.com</b-list-group-item
              >
              <b-list-group-item href="mailto:teknis@rumahweb.com"
                ><b>Technical Support :</b>
                teknis@rumahweb.com</b-list-group-item
              >
            </b-list-group>
          </template>
        </RWCard>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  components: {
    RWCard
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Support" },
      { title: "Trouble Ticket" }
    ]);
  }
};
</script>
